// import Swiper JS
import Swiper, { Navigation } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

window.addEventListener('DOMContentLoaded', () => {
    // homepage slider
    const homepageHero = new Swiper('.hero-slider', {
        modules: [Navigation],
        loop: true,
        navigation: {
            nextEl: '.hero-button-next',
            prevEl: '.hero-button-prev',
        },
    });
});
